body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #001f3f;
  color: #ffffff;
}

.App {
  text-align: center;
  padding: 20px;
}

h1, h2 {
  color: #ffdc00;
}

form {
  margin-bottom: 20px;
}

input[type="file"] {
  display: block;
  margin: 10px auto;
  padding: 10px;
  background-color: #0074d9;
  border: none;
  color: #ffffff;
  cursor: pointer;
}

input[type="file"]::-webkit-file-upload-button {
  background: #001f3f;
  border: 1px solid #0074d9;
  color: #ffffff;
  padding: 10px;
  cursor: pointer;
}

input[type="checkbox"] {
  transform: scale(2); /* Increase checkbox size */
  margin: 0;
  padding: 0;
  display: block; /* Ensure it's treated as a block element */

}

select {
  margin: 10px;
  padding: 10px;
  background-color: #0074d9;
  border: none;
  color: #ffffff;
  cursor: pointer;
}

button {
  padding: 10px 20px;
  background-color: #0074d9;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  margin: 10px;
}

button:hover {
  background-color: #005bb5;
}

.response {
  background-color: #003366;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  text-align: left;
}


/* Add this CSS to increase horizontal spacing between table rows */
tbody tr {
  margin-bottom: 10px; /* Adjust spacing between rows */
}

/* Adjust padding and margin for table cells */
table td, table th {
  padding: 30px;
  text-align: center;
}

table {
  margin: 0 auto; /* Auto margins horizontally center the table */

}

.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-icon {
  display: flex;
  align-items: center;
  gap: 10px;
}


table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  border: 2px solid white; /* Black border */
  padding: 8px;
  text-align: left; /* Align text to the left */
}
/* 

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; 
}

.button {
  margin: 10px;
  padding: 10px 20px;
  font-size: 16px; 
} */
