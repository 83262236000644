#button-container {
    display: flex;
    flex-direction: column; /* Stack buttons vertically */
    align-items: center;    /* Center the buttons horizontally */
    justify-content: center; /* Center the buttons vertically within the container */
    height: 100%;  
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center items vertically */
    align-items: center;     /* Center items horizontally */
    height: 100vh;          /* Ensure the button container takes full height */
}

.button {
    margin: 10px;
    padding: 10px 20px;
    font-size: 16px;
    width: 200px;           /* Set a fixed width for both buttons */
    height: 50px;          /* Set a fixed height for both buttons */
    cursor: pointer;        /* Change cursor to pointer on hover */
    /* Optional: Add a background color and border */
    background-color: #007bff; /* Example background color */
    color: white;          /* Button text color */
    border: none;          /* Remove border */
    border-radius: 5px;    /* Add rounded corners */
    transition: background-color 0.3s; /* Smooth background color transition */
}

.button:hover{
    background-color: #0056b3; /* Darken background on hover */
}