.container {
    padding: 20px;
    max-width: 60vw;
    margin: auto;
    background-color: #001f3f; /* Light background for contrast */
    /* border-radius: 8px; */
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
}

h1, h2 {
    color: white; /* Darker text for headings */
}

nav {
    margin-bottom: 20px;
}

nav button {
    margin-right: 10px;
}

button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #0056b3;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

table th, table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #dee2e6; /* Light border */
}

input[type="text"] {
    padding: 10px 15px;
    margin: 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    width: 25vw;
}

.spinner {
    margin: 10px;
    font-size: 24px;
    color: #007bff;
}

.error {
    color: red; /* Set text color to red */
    background-color: #ffcccc; /* Optional: light red background */
    padding: 10px; /* Optional: add some padding */
    border: 1px solid red; /* Optional: add a red border */
    border-radius: 5px; /* Optional: round corners */
    margin: 10px 0; /* Optional: margin for spacing */
}
