.App {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    background-color: #001f3f; /* Light background */
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

h1 {
    color: white; /* Darker color for headings */
}

nav {
    margin-bottom: 20px;
}

nav button {
    margin-right: 10px;
}

button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #0056b3;
}

table {
    width: 100%;
    border-collapse: collapse;
}

table th, table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #dee2e6; /* Light border */
}

input[type="file"] {
    margin-bottom: 10px;
}

input[type="text"] {
    padding: 5px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    width: 10px;
}

.spinner {
    margin: 10px;
    font-size: 24px;
    color: #007bff;
}

.checkbox-wrapper {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
}

.progress-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

progress {
    width: 100%; /* Make it fill the width of the cell */
    height: 20px; /* Adjust height */
    border-radius: 5px; /* Rounded corners */
    background-color: #f3f3f3; /* Background color */
    overflow: hidden; /* Hide overflow */
}

progress::-webkit-progress-value {
    background-color: #4caf50; /* Change to desired color */
}

progress::-moz-progress-bar {
    background-color: #4caf50; /* Change to desired color */
}
